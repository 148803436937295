const StartPage = () => import( /* webpackChunkName: "StartPage" */ './pages/StartPage');
const StartOverview = () => import( /* webpackChunkName: "StartOverview" */ './pages/StartPage/StartOverview');

const pages = [
    {
      path: '/',
      redirect: '/start-page'
    },
    {
      path: '/start-page',
      name: 'StartPage',
      component: StartPage,
      children: [
        {
          path: '',
          redirect: '/start-page/overview'
        },
        {
          path: 'overview',
          name: 'StartOverview',
          component: StartOverview
        }
      ]
    }
];

export {
  pages
}
