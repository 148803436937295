import { createApp } from 'vue';
import App from './App.vue';
import { createRouter } from 'vue-router';
import { routerConfig } from './router.mjs';
import { i18n } from './i18n';
import { createPinia } from 'pinia'
import { interceptor } from '@dutypay/front-end-design-system';
import './styles.scss';
import { baseComponents } from '@dutypay/front-end-design-system';
import mitt from 'mitt';
import { name, version } from '../package.json';


const emitter = mitt();
const pinia = createPinia()
const app = createApp(App);
const router = createRouter(routerConfig);
app.config.globalProperties.$http = interceptor;

app
    .use(router)
    .use(pinia)
    .use(i18n)

Object.entries(baseComponents).forEach(([name, component]) => {
    app.component(name, component)
})

app
    .provide('router', router)
    .provide('emitter', emitter);

app.mount("#app");

console.log( name + " version: ", version)


