import { defineAsyncComponent } from "vue";

const LoadingPage = () => import(/* webpackChunkName: "LoadingPage" */ '@dutypay/settings-pages/src/components/LoadingPage');

const AccountSettings = () => import(/* webpackChunkName: "AccountSettings" */ './pages/AccountSettings.vue');

const PersonalInformation = defineAsyncComponent({
    loader: () => import( /* webpackChunkName: "PersonalInformation" */ './pages/AccountSettings/PersonalInformation.vue'),
    loadingComponent: LoadingPage
});
const InterfaceSettings = defineAsyncComponent({
    loader: () => import( /* webpackChunkName: "InterfaceSettings" */ './pages/ApplicationSettings/InterfaceSettings.vue'),
    loadingComponent: LoadingPage
});
const CompanySwitch = defineAsyncComponent({
    loader: () => import( /* webpackChunkName: "CompanySwitch" */ './pages/ApplicationSettings/CompanySwitch.vue'),
    loadingComponent: LoadingPage
});
const SecuritySettings = defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "SecuritySettings" */ '@dutypay/settings-pages/src/pages/AccountSettings/SecuritySettings'),
    loadingComponent: LoadingPage
});

const CompanySettings = () => import(/* webpackChunkName: "CompanySettings" */ "./pages/CompanySettings.vue");
const IntegrationSettings = defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "IntegrationSettings" */ '@dutypay/settings-pages/src/pages/CompanySettings/IntegrationSettings'),
    loadingComponent: LoadingPage
});
const CompanyInformation = defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "CompanyInformation" */ './pages/CompanySettings/CompanyInformation.vue'),
    loadingComponent: LoadingPage
});


import { pages } from './pages.mjs';

export const routes = [
    // ----------------
    //  PAGES
    // ----------------

    ...pages,

    // ----------------
    //  SETTINGS
    // ----------------
    {
        path: '/account-settings',
        component: AccountSettings,
        children: [
            {
                path: 'personal-information',
                component: PersonalInformation
            },
            {
                path: 'security-settings',
                component: SecuritySettings
            }
        ]
    },
    
    {
        path: '/application-settings',
        component: AccountSettings,
        children: [
            {
                path: 'company-switch',
                component: CompanySwitch
            },
            {
                path: 'interface-settings',
                component: InterfaceSettings
            }
        ]
    },

    
    {
        path: '/company-settings',
        component: CompanySettings,
        children: [{
            path: 'integrations',
            component: IntegrationSettings
        },
        {
            path: 'company-information',
            component: CompanyInformation
        }
        ]
    },
];