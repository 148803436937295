import {computed} from 'vue';
import { i18n } from './i18n';
const { t } = i18n.global;

const menuItems = computed(() => [{
            slug: 'start-page',
            path: '/start-page',
            name: 'StartPage',
            label: t('submissionshub.pages.menuItems.startPage.linkLabel'),
            children: [
                {
                    path: '/start-page/overview',
                    name: 'StartOverview',
                    label: t('submissionshub.pages.menuItems.startPage.children.overview.linkLabel')
                }
            ]
        }]);

    
export {
    menuItems
}
