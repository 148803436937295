<template>
    <DpApplicationContainer v-if="isAuth" :menuItems="menuItems">
      <DpApplicationRemindersList :modelValue="reminders" />
      <router-view/>
    </DpApplicationContainer>
</template>

<script setup>
import { ref, onBeforeMount, defineComponent, defineAsyncComponent, computed, watch, h } from "vue";
import { useAuthStore, useCompanyStore, useEnvStore } from "@dutypay/front-end-design-system";
import { useI18n } from "vue-i18n";
import { menuItems } from './menuItems.js';
import { interceptor } from '@dutypay/front-end-design-system';
import { to } from "await-to-js";
import { isEmpty } from 'lodash';

const authStore = useAuthStore()
const envStore = useEnvStore()
const isAuth = computed(() => {
  return authStore.status === 'success'
})
const reminders = ref([]);
const companyStore = useCompanyStore();

const { locale } = useI18n({ useScope: 'global' })
// const elementLocale = computed(() => {
//   return elementLocales[locale.value];
// })

async function remoteFetchReminder() {
  if (isEmpty(companyStore.selectedCompany))
    return;
  let err, remoteFetch;
  [err, remoteFetch] = await to(
      interceptor.get(`${ envStore.apiUrls.dutypay }/api/v0/reminder-data/${ companyStore.selectedCompany.hs.company.id }`)
  );
  if (err) {
    return;
  }
  reminders.value = remoteFetch.data.result;
}

const DpApplicationRemindersList = defineComponent({
  props: {
    modelValue: { type: Array, required: true }
  },
  setup(props) {
    const loadComponent = (componentName) => {
      return defineAsyncComponent(() =>
        import(`@dutypay/front-end-sections/src/reminders/${componentName}.vue`)
        .catch(error => {
          if (envStore.debug) throw error;
          return null;
        })
      );
    };

    function renderReminder() {
      let renderFunctions = [];

      if (isEmpty(companyStore.selectedCompany))
        renderFunctions.push(h(loadComponent('CompanySelector'), {}))

      for (const reminder of props.modelValue) {
        const ReminderComponent = loadComponent(reminder._reminder.type);
        if (ReminderComponent) {
          renderFunctions.push(h(ReminderComponent, { modelValue: reminder }));
        }
      }
      return renderFunctions;
    }

    return () => h('div', null, renderReminder());
  }
});


watch(locale, async () => {
  envStore.setGlobalLoading();
  await companyStore.initCompanyStore();
  await remoteFetchReminder();
  envStore.releaseGlobalLoading();
})

async function initApp() {
  envStore.setGlobalLoading()
  await envStore.fetchHosts()
  await authStore.tokenAuth()
  await companyStore.initCompanyStore()
  await remoteFetchReminder()
  envStore.releaseGlobalLoading()
}

onBeforeMount(async () => {
  await initApp();
})
</script>

<i18n>
{}
</i18n>
